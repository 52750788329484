import React from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  InputGuesser,
} from "@api-platform/admin";


const OnboardingCustomTextEdit = props => {
  const transform = (data) => {
    const { examParts, ...rest } = data;
    return rest;
  };
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm redirect="show">
        <TextInput source="locale"/>
        <InputGuesser source="statementTitle"/>
        <InputGuesser source="statementSubtitle"/>
        <ReferenceInput source="establishment" reference="establishments" perPage={1000}>
          <SelectInput optionText="name"/>
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
};
export default OnboardingCustomTextEdit;
