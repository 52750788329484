import React from "react";
import {ChipField, ReferenceField, TextField, ReferenceArrayField, SingleFieldList} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const SubjectShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <FieldGuesser source="description" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <ReferenceArrayField reference="school-classes" source="schoolClasses" addLabel={true}>
      <SingleFieldList>
        <ChipField source="name"/>
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="createdAt" addLabel={true}/>
    <FieldGuesser source="updatedAt" addLabel={true}/>
  </ShowGuesser>
);

export default SubjectShow;
