const findFirstExistingProperty = (properties, errorMessage) => {
    const notEmptyProperties = properties.filter(property => property !== undefined);
    if (notEmptyProperties.length === 0) {
        throw new Error(errorMessage);
    }
    return notEmptyProperties[0];
}

const resolveVariable = (name) => {
    return findFirstExistingProperty([window.env[name], process.env[name]], `Failed to resolve ${name}`);
}

export const API_ENTRYPOINT = resolveVariable("REACT_APP_API_ENTRYPOINT");
