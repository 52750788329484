import React, { useEffect, useState } from 'react';
import { SelectInput, useDataProvider, required } from 'react-admin';

const DesktopVersionSelectInput = (props) => {
  const [choices, setChoices] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('desktop-versions', {
      pagination: {},
      sort: { field: 'version', order: 'DESC' },
      filter: { os: 'Windows' },
    })
      .then(({ data }) => setChoices(data))
      .catch(error => {
        console.error('Error fetching desktop versions', error);
      });
  }, [dataProvider]);

  return (
    <SelectInput
      {...props}
      choices={choices}
      optionText="version"
      optionValue="version"
      validate={[required()]}
    />
  );
};

export default DesktopVersionSelectInput;
