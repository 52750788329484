import React from "react";
import { EditGuesser,InputGuesser } from "@api-platform/admin";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const SchoolClassForm = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
      <Box p="1em">
          <Box display="flex">

              <Box flex={1} mr="4em">
                  <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                  <TextInput source="name" label='resources.school-classes.fields.name' fullWidth />
                  <ReferenceInput source="academicYear" reference="academic-years" label='resources.school-classes.fields.academicYear' fullWidth perPage={1000} >
                      <SelectInput optionText="name" />
                  </ReferenceInput>
                  <ReferenceArrayInput source="subjects" reference="subjects" label='resources.school-classes.fields.subjects' filter={{ 'establishment': props.record.establishment }} perPage={1000} sort={{ field: 'name', order: 'ASC' }} fullWidth>
                      <SelectArrayInput optionText="name" />
                  </ReferenceArrayInput>
                  <ReferenceArrayInput source="students" reference="users" label='resources.school-classes.fields.students' filter={{ currentEstablishment: props.record.establishment, roles: 'ROLE_STUDENT'}} perPage={1000} sort={{ field: 'lastName', order: 'ASC' }} fullWidth>
                      <SelectArrayInput optionText="lastName" />
                  </ReferenceArrayInput>
                  <ReferenceInput source="establishment" reference="establishments" label='resources.school-classes.fields.establishment' onChange={()=>notify(`resources.changeEstablishment`,'warning')} fullWidth perPage={1000}>
                      <SelectInput optionText="name" />
                  </ReferenceInput>
              </Box>

          </Box>
      </Box >
  )
}

const SchoolClassEdit = props => (
  <EditGuesser {...props}>
    <SchoolClassForm />
    <InputGuesser source="deleted" />
  </EditGuesser>
);

export default SchoolClassEdit;
