import React from "react";
import {EditGuesser} from "@api-platform/admin";
import { EstablishmentForm } from "./EstablishmentForm";

const EstablishmentEdit = props => {
  return (
    <EditGuesser {...props}>
      <EstablishmentForm />
    </EditGuesser>
  )
};

export default EstablishmentEdit;

