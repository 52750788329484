import React from "react";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  AutocompleteArrayInput,
  useRedirect,
} from 'react-admin';
import {
  CreateGuesser
} from "@api-platform/admin";

const UserDetailCreate = props => {
  let array = props.location.pathname.split('/');
  let user_id = "/users/"+array[array.length-1];
  const redirect = useRedirect();
  const onSuccess = () => {
    redirect("/users/"+user_id+"/show/user-details");
  };
  return (
    <CreateGuesser onSuccess={onSuccess} {...props} >
      <ReferenceInput source="user" reference="users" perPage={1000}>
        <SelectInput
          optionText="fullName"
          optionValue="id"
          autoFocus={true}
          select={true}
          initialValue={user_id}
          defaultValue={user_id}
        />
      </ReferenceInput>
      <ReferenceInput source="establishment" reference="establishments" perPage={1000}>
        <SelectInput optionText="name"/>
      </ReferenceInput>
      <AutocompleteArrayInput source="roles" choices={[
        {id: 'ROLE_STUDENT', name: 'ROLE STUDENT'},
        {id: 'ROLE_PROCTOR', name: 'ROLE PROCTOR'},
        {id: 'ROLE_PROCTOR_MANAGER', name: 'ROLE PROCTOR MANAGER'},
        {id: 'ROLE_TEACHER', name: 'ROLE TEACHER'},
        {id: 'ROLE_GRADER', name: 'ROLE GRADER'},
        {id: 'ROLE_MANAGER', name: 'ROLE MANAGER'},
        {id: 'ROLE_SUPER_ADMIN', name: 'ROLE SUPER ADMIN'},
      ]}/>
      <TextInput source="email" type="email"/>
      <BooleanInput source="hasExtraTime"/>
      <TextInput source="locale"/>
      <TextInput source="studentNumber"/>
    </CreateGuesser>
  );
};

export default UserDetailCreate;
