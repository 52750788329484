import React from "react";
import {
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
  Filter,
  SearchInput,
  TextInput,
} from 'react-admin';

import {
  FieldGuesser,
  ShowGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const SchoolClassExpand = props => (
  <ShowGuesser {...props} actions={false}>
    <ReferenceArrayField reference="subjects" source="subjects" sortable={false}>
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
  </ShowGuesser>

)

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput source="establishment.name" label="resources.establishments.name" />
    <TextInput source="subjects.name" label="resources.subjects.name" />
    <TextInput source="academicYear.name" label="resources.academic-years.name" />
    <TextInput source="nbSubjects" />
    <TextInput source="nbStudents" />
    <TextInput source="createdAt" />
    <TextInput source="updatedAt" />
  </Filter>
);

const SchoolClassList = props => (
  <List {...props} filters={<PostFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
    <Datagrid expand={<SchoolClassExpand />}>
      <FieldGuesser source="classId" label="id" />
      <FieldGuesser source="name" />
      <ReferenceField source="academicYear" reference="academic-years" sortBy="academicYear.name">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="nbSubjects" />
      <FieldGuesser source="nbStudents" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List >
);

export default SchoolClassList;
