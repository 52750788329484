import frenchMessages from 'ra-language-french';

export const messages = {
  resources: {
    general: "Général",
    changeEstablishment: "Attention : vous venez de changer d'établissement",
    'establishment-params': {
      name: 'Paramètres étab.',
      fields: {
        establishment: 'Établissement',
        enableEquipmentTesting: "Test du matériel",
        enableTimeInterval: "Intervalle de temps",
        enableSpecificExamTime: "Temps d’examen spécifique",
        hasPicturesInChoices: "Images dans les choix",
        commentedTextGrading: "Correction dessin",
        hasQuestionBank: "Banque de questions",
        isSpreadsheetEnabled: "Question de type “feuille de calcul”",
        enabledAIQuestionsGeneration: "Génération de questions via l’IA",
        enabledAiCorrection: "Correction Intelligente via l'IA",
        hasPredefinedProctorWeComments: "Commentaires prédéfinis pour le proctoring",
        facialIdentificationReferencePict: "Prévalidation des photos d’indentité",
      },
    },
    'establishments': {
      name: 'Établissements',
      fields: {
        name: 'Nom',
        customSupportPhoneNumber: 'Numéro de téléphone du support',
        description: 'Description',
        email: 'Email',
        academicYear: 'Année scolaire',
        learningGoalManaged: "Objectif d'apprentissage",
        logo: 'Logo',
        allowExportCopy: "Export des copies",
        adminGradeSharing: "Administrateur",
        graderGradeSharing: "Correcteur",
        gradeSharing: "Partage des notes",
        allowProctoring: "Proctoring",
        timeZeroSecond: "Temps zéro seconde",
        proctoringStorageDuration: "Durée de stockage - proctocting",
        subjects: 'Matières',
        users: 'Utilisateurs',
        classes: 'Classes',
        establishmentLanguage: "Langue de l'établissement",
        createdAt: 'Crée le',
        updatedAt: 'Modifié le',
        period: {
          period: 'Période',
          name: 'Nom de la période',
          description: 'Description de la période',
          startDate: "Début de la période",
          endDate: 'Fin de la période',
          current: 'Période en cours',
          createdAt: 'Crée le',
          updatedAt: 'Modifié le',
        },
        fromEmails: "Depuis l'email",
        allowProctoringLive: "Proctoring Live",
        allowProctoringAudioRecord: "Proctoring Enregistrement Audio",
        allowEnvironment: "Environnement",
        lang: 'Langue',
        info: 'Informations',
        option: 'Options',
        hasSession: "Mode Session",
        durationFixed: "La durée est fixe",
        questionsPoints: "Afficher la notation",
        timeOptions: "Afficher la durée d’accessibilité",
        noCorrectAnswer: 'Afficher la réponse "Aucune bonne réponse"',
        allowEarlyExitFromRemoteExam: "Autoriser la sortie anticipée de l'examen à distance",
        multipleGrading: "Correction multiple",
        mandatoryTraining: "Désactiver les tests d'entrainement",
        virusAlert: "Alerte antivirus",
        totalRamDetection: "Détection de la mémoire RAM totale",
        disableMailing: "Désactivation de l'envoi des emails",
        disabledMailings: "Types de mails désactivés",
        webApp: "Mode Web",
        remoteExam: "Mode externe",
        subjectExportPermissions: "Export du sujet",
        desktopTargetVersion: "Version cible du logiciel",
        desktopMinimalVersion: "Version minimale du logiciel"
      }
    },
    'academic-years': {
      name: 'Années scolaires',
      fields: {
        name: 'Nom',
        description: 'Description',
        startDate: "Début",
        endDate: 'Fin',
        establishment: 'Établissement',
        createdAt: 'Crée le',
        updatedAt: 'Modifié le',
        general: "Général",
      },
    },
    'school-classes': {
      name: 'Classes',
      fields: {
        name: 'Nom',
        academicYear: 'Année scolaire',
        subjects: 'Matières',
        students: 'Étudiants',
        establishment: 'Établissement',
        deleted: 'Supprimé',
        createdAt: 'Crée le',
        updatedAt: 'Modifié le',
        nbSubjects: 'nombre de Matière',
        nbStudents: "nombre d'étudiant"
      }
    },
    'subjects': {
      name: 'Matières',
      fields: {
        name: 'Nom',
        archived: 'Archivée',
        description: 'Description',
        programs: 'programmes',
        learningGoals: 'Buts',
        learningObjectives: 'Objectifs éducatifs',
        coefficients: 'Coefficients',
        establishment: 'Établissement',
        schoolClasses: 'Classes',
        subjectGroup: 'Groupe de matières',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
      }
    },
    'programs': {
      name: 'Programmes',
      fields: {
        name: 'Nom',
        description: 'Description',
        learningGoals: 'Buts',
        subject: 'Matière',
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
        establishment: 'Établissement',
        nbLearningGoals: 'Learning Goals Number',
        nbLearningObjectives: 'Learning Objectives Number'

      }
    },
    'users': {
      name: 'Utilisateurs',
      fields: {
        firstName: 'Prénom',
        lastName: 'Nom',
        username: 'Nom d\'utilisateur',
        email: 'Email',
        roles: 'Roles',
        hasExtraTime: 'Temps supplementaire',
        blocked: 'Bloqué',
        deleted: 'Supprimé',
        schoolClasses: 'Classes',
        establishments: 'Établissements',
        exams: "Examens",
        createdBy: "Créé par",
        createdAt: 'Créé le',
        updatedAt: 'Modifié le',
        establishment: 'Établissement',
        user_roles: 'Roles',
        professorAssessmentTypes: 'Type d\'évaluation (professeur)',
        currentEstablishment: 'Établissement actuel',
        user: 'Utilisateur',
        hasTakenTraining:"Has Taken Training",
        takenTrainingDate:"Taken Training Date"
      },
    },
    'user-details': {
      name: 'Détails utilisateur',
      fields: {

      }
    },
    'exams': {
      name: 'Examens',
      fields: {
        name: 'Nom',
        subject: 'Matière',
        academicYear: 'Année scolaire',
        professors: 'Professeurs',
        proctors: 'Surveillants',
        classes: 'Classes',
        nbrClasses: 'Nombre de classes',
        assessmentType: 'Type d\'évaluation',
        startDate: 'Date et Heure de début (UTC)',
        duration: 'Durée (SEC)',
        instructions: 'Consignes',
        status: 'Statut',
        hash: 'Hash',
        publicKey: 'Clé publique',
        proctorCode: 'Code surveillant',
        examParams: {
          allowSpellChecker: "Autoriser le vérificateur d'orthographe",
          fields: {
            allowSpellChecker: "Autoriser le vérificateur d'orthographe",
          }
        },
        hasTimer: 'Timer ?',
        createdBy: 'Créé par',
        establishment: 'Établissement',
        createdAt: 'Crée le',
        updatedAt: 'Modifié le',
        receivedStudentPapers: 'Copies reçues',
        studentPapersDistributions: 'Répartition de la correction',
        timezone:"Fuseau Horaire",
        maxPoints:"Note Maximale",
        timeZeroSecond:"Time Zero Second (SEC)"
      }
    },
    'student-paper-distributions': {
      name: 'Répartition de la correction',
      fields: {
        grader: 'Correcteur',
        percentage: 'Pourcentage',
        assignedStudentPapers: 'Copies distribuées',
      }
    },
    'exam-params': {
      name: 'Paramètres d\'examen',
      fields: {
        grader: 'Correcteur',
        percentage: 'Pourcentage',
        assignedStudentPapers: 'Copies distribuées',
        allowSpellChecker: "Autoriser le vérificateur d'orthographe",
        allowCopyAndPaste: 'Autoriser le copier-coller',
        anonymousCorrection: 'Copies Correction anonyme',
        scientificCalculator: 'Autoriser la calculatrice scientifique',
        basicCalculator: 'Autoriser la calculatrice basique',
        proctoring: 'Activer le proctoring',
        openBook: 'Livres ouverts',
        allowTierTime: 'Autoriser le temps supplementaire',
        deleteFileAfterFtpUpload: 'Suprrimer les fichiers après la mise à jour   ',
        encryptFileOnFtp: 'Crypter les fichiers (Ftp)',
        sendWithFtp: 'Envoyer avec Ftp',
        showNoCorrectAnswer: 'Afficher la réponse "Aucune bonne réponse"',
      }
    },
    'assessment-types': {
      name: 'Types d\'évaluation',
      fields: {
        name: 'Nom',
        doubleApproval: 'Double validation',
        establishment: 'Établissement',
        createdAt: 'Crée le',
        updatedAt: 'Modifié le',
      }
    },
    'lms-configurations': {
      name: 'Configurations LMS',
      fields: {
        name: 'Nom',
        type: 'Type',
        token: 'Token',
        classes: 'Classes',
        subjects: 'Matières',
        users: 'Utilisateurs',
        enabled: 'Activé',
        establishment: 'Établissement',
      },
      'onboarding-custom-texts': {
        name: 'Onboarding custom texts',
        fields: {
          locale: 'Locale',
          statementTitle: 'Statement Title',
          statementSubtitle: 'Statement Subtitle',
          establishment: 'Établissement',
        }
      }
    },
  },
  ...frenchMessages,
};

export default messages;
