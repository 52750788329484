import React from "react";
import {ReferenceField, TextField} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const LmsConfigurationShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <FieldGuesser source="type" addLabel={true}/>
    <FieldGuesser source="token" addLabel={true}/>
    <FieldGuesser source="classes" addLabel={true}/>
    <FieldGuesser source="subjects" addLabel={true}/>
    <FieldGuesser source="users" addLabel={true}/>
    <FieldGuesser source="enabled" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>

  </ShowGuesser>
);

export default LmsConfigurationShow;
