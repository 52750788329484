import React from "react";
import {
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
  Filter,
  SearchInput,
  TextInput,
  DateField,
} from 'react-admin';
import {
  FieldGuesser,
  ShowGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const ExamExpand = props => (
  <ShowGuesser {...props} actions={false}>
    <ReferenceArrayField source="classes" reference="school-classes" sortable={false} link="show">
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ShowGuesser>

)
const PostFilter = (props) => (
  <Filter {...props}>

    <SearchInput source="search" alwaysOn />
    <TextInput source="id" />
    <TextInput source="name" />
    <TextInput source="publicKey" />
    <TextInput source="duration" />
    <TextInput source="status" />
    <TextInput source="maxPoints" />
    <TextInput source="startDate" />
    <TextInput source="updatedAt" />
    <TextInput source="createdBy.fullname" label='resources.exams.fields.createdBy' />
    <TextInput source="validatedAt" />
    <TextInput source="nbClasses" />
    <TextInput source="nbProfessors" />
    <TextInput source="nbGraders" />
    <TextInput source="subject.name" label='resources.subjects.name' />
  </Filter>
);


const ExamList = props => (
  <List {...props} filters={<PostFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25}                                                                   >
    <Datagrid expand={<ExamExpand />}>
      <FieldGuesser source="examId" label="id" />
      <FieldGuesser source="name" />
      <ReferenceField source="subject" reference="subjects" link="show">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="nbClasses" />
      <DateField source="startDate" showTime />
      <FieldGuesser source="duration" />
      <FieldGuesser source="maxPoints" />
      <FieldGuesser source="timeZeroSecond" />
      <FieldGuesser source="timezone" />
      <FieldGuesser source="publicKey" />
      <FieldGuesser source="proctorCode" />
      <FieldGuesser source="status" />
      <FieldGuesser source="assessmentType" />
      <FieldGuesser source="nbProfessors" />
      <FieldGuesser source="nbGraders" />
      <FieldGuesser source="type" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="validatedAt" />
      <ReferenceField source="createdBy" reference="users">
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField source="template" reference="exams" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List >
);

export default ExamList;
