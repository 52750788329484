import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { AssessmentTypeForm } from "./AssessmentTypeForm";


const AssessmentTypeCreate = props => (
  <CreateGuesser {...props}>
    <AssessmentTypeForm />
  </CreateGuesser>
);

export default AssessmentTypeCreate;
