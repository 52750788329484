import React, { Fragment } from "react";
import {
    BulkDeleteWithConfirmButton,
    BulkExportButton,
} from 'react-admin';


export const PostBulkActionButtons = props => (
    <Fragment>
        <BulkExportButton {...props} />
        <BulkDeleteWithConfirmButton {...props} />

    </Fragment>
);
