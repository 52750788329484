import React from "react";
import {
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';

import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const LmsConfigurationForm = () => {
    const translate = useTranslate();
    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label='resources.lms-configurations.fields.name' fullWidth />
                    <TextInput source="type" label='resources.lms-configurations.fields.type' fullWidth />
                    <TextInput source="token" label='resources.lms-configurations.fields.token' fullWidth />
                    <ReferenceInput source="establishment" reference="establishments" label='resources.lms-configurations.fields.establishment' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <BooleanInput source="enabled" label='resources.lms-configurations.fields.enabled' fullWidth />
                    <ArrayInput source="classes" label='resources.lms-configurations.fields.classes' fullWidth>
                        <SimpleFormIterator>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="subjects" label='resources.lms-configurations.fields.subjects' fullWidth>
                        <SimpleFormIterator>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput source="users" label='resources.lms-configurations.fields.users' fullWidth>
                        <SimpleFormIterator>
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Box>

            </Box>
        </Box >
    )
}


