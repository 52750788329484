import React from "react";
import { EditGuesser } from "@api-platform/admin";
import {
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const SubjectForm = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
    <Box p="1em">
      <Box display="flex">
        <Box flex={1} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <TextInput source="name" label='resources.subjects.fields.name' fullWidth />
          <TextInput source="description" label='resources.subjects.fields.description' fullWidth />
          <ReferenceInput source="establishment" reference="establishments" label='resources.subjects.fields.establishment' onChange={()=>notify(`resources.changeEstablishment`,'warning')} fullWidth perPage={1000}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput source="schoolClasses" reference="school-classes" label='resources.subjects.fields.schoolClasses' filter={{ 'establishment': props.record.establishment }} perPage={1000} sort={{ field: 'name', order: 'ASC' }} fullWidth >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <BooleanInput source="archived" label='resources.subjects.fields.archived' defaultValue={false} fullWidth />
        </Box>
      </Box>
    </Box>
  )
}

const SubjectEdit = props => (
  <EditGuesser {...props}>
    <SubjectForm />
  </EditGuesser>
);

export default SubjectEdit;
