import React from "react";
import {
    DateInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";


export const AcademicYearForm = () => {
    const translate = useTranslate();
    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label='resources.academic-years.fields.name' fullWidth />
                    <TextInput source="description" label='resources.academic-years.fields.description' fullWidth />
                    <ReferenceInput source="establishment" reference="establishments" label='resources.academic-years.fields.establishment' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <DateInput source="startDate" label='resources.academic-years.fields.startDate' fullWidth />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <DateInput source="endDate" label='resources.academic-years.fields.endDate' fullWidth />
                        </Box>
                    </Box>
                    <BooleanInput source="current" fullWidth />
                </Box>

            </Box>
        </Box>
    )
}
