import React, { useEffect, useState } from 'react';
import { SelectInput, useDataProvider, required } from 'react-admin';

const LanguageSelectInput = (props) => {
  const [choices, setChoices] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    dataProvider.getList('languages', {
      pagination: {},
      sort: {},
    })
      .then(({ data }) => setChoices(data))
      .catch(error => {
        console.error('Error fetching languages', error);
      });
  }, [dataProvider]);

  return (
    <SelectInput
      {...props}
      choices={choices}
      optionText="label"
      optionValue="isoCode"
      validate={[required()]}
    />
  );
};

export default LanguageSelectInput;
