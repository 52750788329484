import React from "react";
import { EditGuesser } from "@api-platform/admin";
import {
  DateInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";


const AcademicYearForm = () => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
      <Box p="1em">
          <Box display="flex">

              <Box flex={1} mr="4em">
                  <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                  <TextInput source="name" label='resources.academic-years.fields.name' fullWidth />
                  <TextInput source="description" label='resources.academic-years.fields.description' fullWidth />
                  <ReferenceInput source="establishment" reference="establishments" label='resources.academic-years.fields.establishment' onChange={()=>notify(`resources.changeEstablishment`,'warning')} fullWidth perPage={1000}>
                      <SelectInput optionText="name" />
                  </ReferenceInput>
                  <Box display="flex">
                      <Box flex={1} mr="0.5em">
                          <DateInput source="startDate" label='resources.academic-years.fields.startDate' fullWidth />
                      </Box>
                      <Box flex={1} ml="0.5em">
                          <DateInput source="endDate" label='resources.academic-years.fields.endDate' fullWidth />
                      </Box>
                  </Box>
                  <BooleanInput source="current" fullWidth />
              </Box>

          </Box>
      </Box>
  )
}
const PostTitle = ({ record }) => {
  return <span>Edition {record ? `"${record.name}"` : ''}</span>;
};

const AcademicYearEdit = props => {
  return (
    <EditGuesser title={<PostTitle />} {...props}>
      <AcademicYearForm />
    </EditGuesser>
  );
}

export default AcademicYearEdit;
