import React from "react";
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  AutocompleteArrayInput,
} from 'react-admin';
import {
  EditGuesser,
} from "@api-platform/admin";
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const UserDetailEditForm = () => {
  const translate = useTranslate();
  return (
    <Box p="1em">
      <Box display="flex">

        <Box flex={1} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <ReferenceInput source="user" reference="users" sortBy="user.firstName" label='resources.users.fields.user' fullWidth perPage={1000}>
            <SelectInput optionText="fullName" />
          </ReferenceInput>
          <ReferenceInput source="establishment" reference="establishments" sortBy="establishment.name" label='resources.users.fields.establishment' fullWidth perPage={1000}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="email" label='resources.users.fields.email' fullWidth />
          <AutocompleteArrayInput source="roles" label='resources.users.fields.roles' fullWidth choices={[
            { id: 'ROLE_STUDENT', name: 'ROLE STUDENT' },
            { id: 'ROLE_PROCTOR', name: 'ROLE PROCTOR' },
            { id: 'ROLE_PROCTOR_MANAGER', name: 'ROLE PROCTOR MANAGER' },
            { id: 'ROLE_TEACHER', name: 'ROLE TEACHER' },
            { id: 'ROLE_GRADER', name: 'ROLE GRADER' },
            { id: 'ROLE_MANAGER', name: 'ROLE MANAGER' },
            { id: 'ROLE_SUPER_ADMIN', name: 'ROLE SUPER ADMIN' },
          ]} />
          <TextInput source="studentNumber" fullWidth />
          <TextInput source="locale" fullWidth />
          <BooleanInput source="hasExtraTime" label='resources.users.fields.hasExtraTime' fullWidth />
        </Box>

      </Box>
    </Box>
  )
}

const UserDetailEdit = props => (
  <EditGuesser {...props}>
    <UserDetailEditForm/>
  </EditGuesser>
);

export default UserDetailEdit;
