
import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { EstablishmentForm } from "./EstablishmentForm";

const EstablishmentCreate = props => (
  <CreateGuesser {...props}>
    <EstablishmentForm />
  </CreateGuesser>
);

export default EstablishmentCreate;



