import React from "react";
import { EditGuesser } from "@api-platform/admin";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const ProgramForm = () => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
      <Box p="1em">
          <Box display="flex">

              <Box flex={1} mr="4em">
                  <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                  <TextInput source="name" label='resources.programs.fields.name' fullWidth />
                  <TextInput source="description" label='resources.programs.fields.description' fullWidth />
                  <ReferenceInput source="subject" reference="subjects" label='resources.programs.fields.subject' fullWidth perPage={1000}>
                      <SelectInput optionText="name" />
                  </ReferenceInput>
                  <ReferenceInput source="establishment" reference="establishments" label='resources.programs.fields.establishment' onChange={()=>notify(`resources.changeEstablishment`,'warning')} fullWidth perPage={1000}>
                      <SelectInput optionText="name" />
                  </ReferenceInput>
              </Box>

          </Box>
      </Box >
  )
}

const ProgramEdit = props => (
  <EditGuesser {...props}>
    <ProgramForm />
  </EditGuesser>
);

export default ProgramEdit;
