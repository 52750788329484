import React from "react";
import {CreateGuesser} from "@api-platform/admin";
import { SchoolClassForm } from "./SchoolClassForm";

const SchoolClassCreate = props => (
  <CreateGuesser {...props}>
    <SchoolClassForm />
  </CreateGuesser>
);

export default SchoolClassCreate;
