import React from "react";
import {TextField, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const SchoolClassShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <ReferenceField source="academicYear" reference="academic-years" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <ReferenceArrayField reference="subjects" source="subjects" addLabel={true} label="Matières">
      <SingleFieldList>
        <ChipField source="name"/>
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField reference="users" source="students" addLabel={true} label="Étudiants">
      <SingleFieldList>
        <ChipField source="lastName"/>
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceField source="establishment" reference="establishments" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <FieldGuesser source="deleted" addLabel={true}/>
    <FieldGuesser source="createdAt" addLabel={true}/>
    <FieldGuesser source="updatedAt" addLabel={true}/>
  </ShowGuesser>
);

export default SchoolClassShow;
