import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { AcademicYearForm } from "./AcademicYearForm";

const AcademicYearCreate = props => (
  <CreateGuesser {...props}>
    <AcademicYearForm />
  </CreateGuesser>
);

export default AcademicYearCreate;
