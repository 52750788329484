import React from "react";
import {
  ChipField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  Filter,
  SearchInput,
  TextInput,
  AutocompleteArrayInput,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
} from 'react-admin';
import {
  FieldGuesser,
  ShowGuesser
} from "@api-platform/admin";

import { PostBulkActionButtons } from "../ActionButtons";

const UserExpand = props => (
  <ShowGuesser {...props} actions={false}>
    <ReferenceArrayField reference="establishments" source="establishments" sortable={false}>
      <SingleFieldList>
        <ChipField source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ShowGuesser>

)

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="username" />
    <TextInput source="email" />
    <TextInput source="locale" />
    <TextInput source="takenTrainingDate" />
    <TextInput source="updatedAt" />
    <TextInput source="createdAt" />

    <AutocompleteArrayInput source="user_roles" choices={[
      { id: 'ROLE_STUDENT', name: 'Étudiant' },
      { id: 'ROLE_PROCTOR', name: 'Examinateur' },
      { id: 'ROLE_TEACHER', name: 'Professeur' },
      { id: 'ROLE_GRADER', name: 'Superviseur' },
      { id: 'ROLE_MANAGER', name: 'Manager' },
      { id: 'ROLE_SUPER_ADMIN', name: 'Super Admin' },
    ]} />
  </Filter>
);

const UserList = props => (
  <List {...props} filters={<PostFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
    <Datagrid expand={<UserExpand />} >
      <FieldGuesser source="firstName" />
      <FieldGuesser source="lastName" />
      <FieldGuesser source="username" />
      <FieldGuesser source="email" />
      <FieldGuesser source="studentNumber" />
      <FieldGuesser source="roles" sortable={false} />
      <FieldGuesser source="locale" />
      <FieldGuesser source="hasExtraTime" />
      <FieldGuesser source="hasTakenTraining" />
      <FieldGuesser source="takenTrainingDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List>
)

export default UserList;
