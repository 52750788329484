import React from "react";
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Layout
} from "react-admin";

import LocaleSwitcher from "./LocaleSwitcher";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginLeft: -10
    },
    spacer: {
        flex: 1,
    },
    logo: {
        maxWidth: "40px",
        marginLeft: -35
    },
});

const CustomAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar {...props} color='secondary' >
            <Toolbar>
                <img src={require('./logo.svg')} alt="logo" />
            </Toolbar>
            <Typography
                variant="h6"
                className={classes.title}
            ></Typography>
            <Typography
                variant="h6"
                className={classes.title}
                id="react-admin-title"
            />
            <LocaleSwitcher />
        </AppBar >
    );
};

const MyLayout = props => <Layout {...props} appBar={CustomAppBar} />;

export default MyLayout;