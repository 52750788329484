import React from "react";
import {BooleanField, ReferenceArrayField, SingleFieldList, ChipField, ReferenceField, TextField} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const AcademicYearShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <FieldGuesser source="description" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
      <TextField source="name"/>
    </ReferenceField>
    <ReferenceArrayField reference="school-classes" source="classes" addLabel={true}>
      <SingleFieldList>
        <ChipField source="name"/>
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source="startDate" addLabel={true}/>
    <FieldGuesser source="endDate" addLabel={true}/>
    <FieldGuesser source="createdAt" addLabel={true}/>
    <FieldGuesser source="updatedAt" addLabel={true}/>
    <BooleanField source="current" addLabel={true}/>
  </ShowGuesser>
);

export default AcademicYearShow;
