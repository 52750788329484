import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { LmsConfigurationForm } from "./LmsConfigurationForm";

const LmsConfigurationCreate = props => (
  <CreateGuesser {...props}>
    <LmsConfigurationForm />
  </CreateGuesser>
);

export default LmsConfigurationCreate;
