import React from "react";
import {ReferenceField, TextField} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const programShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel={true}/>
    <ReferenceField source="subject" reference="subjects" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <ReferenceField source="establishment" reference="establishments" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
    <FieldGuesser source="nbLearningGoals" addLabel={true}/>
    <FieldGuesser source="nbLearningObjectives" addLabel={true}/>
    <FieldGuesser source="createdAt" addLabel={true}/>
    <FieldGuesser source="updatedAt" addLabel={true}/>
  </ShowGuesser>
);

export default programShow;
