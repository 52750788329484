import React from "react";
import { CreateGuesser } from "@api-platform/admin";
import { ProgramForm } from "./ProgramForm";

const ProgramCreate = props => (
  <CreateGuesser {...props}>
    <ProgramForm />
  </CreateGuesser>
);

export default ProgramCreate;
