import React from "react";
import {
    DateTimeInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    TextInput,
    AutocompleteInput,
    FormDataConsumer
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const ExamForm = (props) => {
    const translate = useTranslate();


    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label="resources.exams.fields.name" fullWidth />
                    <ReferenceInput source="subject" reference="subjects" label="resources.exams.fields.subject" fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput source="academicYear" reference="academic-years" label="resources.exams.fields.academicYear" fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceArrayInput source="professors" reference="users" label="resources.exams.fields.professors" fullWidth perPage={1000}>
                        <SelectArrayInput optionText="lastName" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="proctors" reference="users" label="resources.exams.fields.proctors" fullWidth perPage={1000}>
                        <SelectArrayInput optionText="lastName" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="classes" reference="school-classes" label="resources.exams.fields.classes" fullWidth perPage={1000}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <ReferenceInput source="assessmentType" reference="assessment-types" label="resources.exams.fields.assessmentType" fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="instructions" label="resources.exams.fields.instructions" fullWidth />
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            {(props.record.status === 'in_creation') ? <AutocompleteInput source="status" label="resources.exams.fields.status" choices={[
                                { id: 'in_creation', name: 'in_creation' },
                                { id: 'finalized', name: 'finalized' },
                            ]} fullWidth /> : (props.record.status === 'to_validate') ? <AutocompleteInput source="status" label="resources.exams.fields.status" choices={[
                                { id: 'to_validate', name: 'to_validate' },
                                { id: 'finalized', name: 'finalized' },
                            ]} fullWidth /> : (props.record.status === 'finalized') ? <AutocompleteInput source="status" label="resources.exams.fields.status" choices={[
                                { id: 'in_creation', name: 'in_creation' },
                                { id: 'to_validate', name: 'to_validate' },
                                { id: 'finalized', name: 'finalized' },
                            ]} fullWidth /> : (props.record.status === 'in_correction') ? <AutocompleteInput source="status" label="resources.exams.fields.status" choices={[
                                { id: 'corrected', name: 'corrected' },
                                { id: 'in_correction', name: 'in_correction' },
                            ]} fullWidth /> : <AutocompleteInput source="status" label="resources.exams.fields.status" choices={[
                                { id: 'corrected', name: 'corrected' },
                                { id: 'in_correction', name: 'in_correction' },
                            ]} fullWidth />}
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <NumberInput step={0.1} source="maxPoints" label="resources.exams.fields.maxPoints" fullWidth />
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <NumberInput source="duration" label="resources.exams.fields.duration" fullWidth />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <TextInput source="timezone" label="resources.exams.fields.timezone" fullWidth />
                        </Box>
                    </Box>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <NumberInput source="timeZeroSecond" label="resources.exams.fields.timeZeroSecond" fullWidth />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <DateTimeInput source="startDate" label="resources.exams.fields.startDate" fullWidth />
                        </Box>
                    </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <AutocompleteInput source="type" choices={[
                        {id: 'default', name: 'default'},
                        {id: 'session', name: 'session'},
                        {id: 'template', name: 'template'},
                      ]}/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <FormDataConsumer>
                        {({formData, dispatch, ...rest}) => {
                          if (formData.type === 'session') {
                            return (
                              <ReferenceInput style={{width: 256}} source="template" reference="exams" filter={{ type: "template" }} allowEmpty>
                                <SelectInput optionText="name" fullWidth/>
                              </ReferenceInput>
                            )
                          } else {
                            return (
                              formData.template=""
                            );
                          }
                        }}
                      </FormDataConsumer>
                    </Box>
                  </Box>
                </Box>
            </Box>
        </Box>
    )
}
