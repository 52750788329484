import React from "react";
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import { ExamForm } from "./ExamForm";


const ExamEdit = props => {
  const transform = (data) => {
    const { examParts, ...rest } = data;
    return rest;
  };
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm >
        <ExamForm/>
      </SimpleForm>
    </Edit>
  )
};
export default ExamEdit;
