import React from "react";
import {
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const AssessmentTypeForm = () => {
    const translate = useTranslate();
    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label='resources.assessment-types.fields.name' fullWidth />
                    <ReferenceInput source="establishment" reference="establishments" label='resources.assessment-types.fields.establishment' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <BooleanInput source="doubleApproval" label='resources.assessment-types.fields.doubleApproval' fullWidth />
                    <BooleanInput source="isDefault"  fullWidth />
                </Box>

            </Box>
        </Box >
    )
}


