import React from "react";
import {
    ReferenceInput,
    SelectInput,
    TextInput,
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const ProgramForm = () => {
    const translate = useTranslate();
    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label='resources.programs.fields.name' fullWidth />
                    <TextInput source="description" label='resources.programs.fields.description' fullWidth />
                    <ReferenceInput source="subject" reference="subjects" label='resources.programs.fields.subject' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput source="establishment" reference="establishments" label='resources.programs.fields.establishment' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextInput source="nbLearningGoals" label='resources.academic-years.fields.nbLearningGoals' fullWidth />
                        </Box>
                        <Box flex={1} ml="0.5em">
                            <TextInput source="nbLearningObjectives" label='resources.academic-years.fields.nbLearningObjectives' fullWidth />
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box >
    )
}


