import React from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  ReferenceArrayInput,
  Edit,
  SimpleForm,
  TextInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const UserEditForm = () => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
    <Box p="1em">
      <Box display="flex">

        <Box flex={1} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <TextInput source="firstName" label='resources.users.fields.firstName' fullWidth />
          <TextInput source="lastName" label='resources.users.fields.lastName' fullWidth />
          <TextInput source="username" label='resources.users.fields.username' fullWidth />
          <TextInput source="email" label='resources.users.fields.email' fullWidth />
          <ReferenceArrayInput
            source="establishments"
            reference="establishments"
            label='resources.users.fields.establishments'
            fullWidth
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
          <ReferenceInput
            source="currentEstablishment"
            reference="establishments"
            label='resources.users.fields.currentEstablishment'
            onChange={()=>notify(`resources.changeEstablishment`,'warning')}
            fullWidth
            perPage={1000}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput source="professorAssessmentTypes" reference="assessment-types" label='resources.users.fields.professorAssessmentTypes' fullWidth>
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <BooleanInput source="locale" label='resources.users.fields.locale' />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput source="takenTrainingDate" label='resources.users.fields.takenTrainingDate' />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <BooleanInput source="hasExtraTime" label='resources.users.fields.hasExtraTime' />
            </Box>
            <Box flex={1} ml="0.5em">
              <BooleanInput source="hasTakenTraining" label='resources.users.fields.hasTakenTraining' />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <BooleanInput source="blocked" label='resources.users.fields.blocked' />
            </Box>
            <Box flex={1} ml="0.5em">

            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

const UserEdit = props => (
  <Edit {...props} redirect="show">
    <SimpleForm redirect="show">
      <UserEditForm />
    </SimpleForm>
  </Edit>
);

export default UserEdit;


