import React from "react";
import {ReferenceField, TextField} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const OnboardingCustomTextShow= props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="locale" addLabel={true}/>
    <FieldGuesser source="statementTitle" addLabel={true}/>
    <FieldGuesser source="statementSubtitle" addLabel={true}/>
    <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name" addLabel={true}>
      <TextField source="name"/>
    </ReferenceField>
  </ShowGuesser>
);

export default OnboardingCustomTextShow;
