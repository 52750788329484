import React from "react";
import MyLayout from "./MyLayout";
import apiAuthenticationProvider from "./security/apiAuthenticationProvider";
import {EstablishmentCreate, EstablishmentEdit, EstablishmentList, EstablishmentShow} from "./establishment";
import {AcademicYearCreate, AcademicYearEdit, AcademicYearList, AcademicYearShow} from "./academicYear";
import {SubjectCreate, SubjectEdit, SubjectList, SubjectShow} from "./subject";
import {SchoolClassCreate, SchoolClassEdit, SchoolClassList, SchoolClassShow} from "./schoolClass";
import {ProgramCreate, ProgramEdit, ProgramList, ProgramShow} from "./program";
import {UserCreate, UserEdit, UserList, UserShow} from "./user";
import {UserDetailCreate, UserDetailEdit, UserDetailList, UserDetailShow} from "./userDetail";
import {ExamEdit, ExamList, ExamShow} from "./exam";
import {AssessmentTypeCreate, AssessmentTypeEdit, AssessmentTypeList, AssessmentTypeShow} from "./assessmentType";
import {
  OnboardingCustomTextCreate,
  OnboardingCustomTextEdit,
  OnboardingCustomTextList,
  OnboardingCustomTextShow
} from "./onboardingCustomText";
import {
  LmsConfigurationCreate,
  LmsConfigurationEdit,
  LmsConfigurationList,
  LmsConfigurationShow
} from "./lmsConfiguration";
import {
  resolveBrowserLocale,
  defaultTheme,
  Resource,
} from "react-admin";
import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import School from "@material-ui/icons/School";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Event from "@material-ui/icons/Event";
import Palette from "@material-ui/icons/Palette";
import {API_ENTRYPOINT} from "./AppConfig";
import {eni18nMessages, fri18nMessages} from "./i18n";
import { createMuiTheme } from '@material-ui/core/styles';
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  useIntrospection,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import { Redirect, Route } from "react-router-dom";
import polyglotI18nProvider from "ra-i18n-polyglot";
import {
  HydraAdmin,
  ResourceGuesser,
} from "@api-platform/admin";

const entrypoint = API_ENTRYPOINT;
const getHeaders = () => localStorage.getItem("token") ? {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};
const fetchHydra = (url, options = {}) =>
  baseFetchHydra(url, {
    ...options,
    headers: getHeaders,
  });
const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem("token")) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};
const apiDocumentationParser = async () => {
  try {
    const { api } = await parseHydraDocumentation(API_ENTRYPOINT, { headers: getHeaders });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem("token");

      return {
        api: result.api,
        customRoutes: [
          <Route path="/" component={RedirectToLogin} />
        ],
      };
    }

    throw result;
  }
};
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    width: 300, // The default value is 240
    closedWidth: 70, // The default value is 55
    secondary: {
      main: "#1D39C4", // Not far from orange
    },
  }
});

// I18n
const messages = {
  fr: fri18nMessages,
  en: eni18nMessages,
};

const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages.fr),
  resolveBrowserLocale()
);

export default () => {
  return (
    <HydraAdmin layout={MyLayout}
                theme={theme}
                dataProvider={dataProvider}
                authProvider={apiAuthenticationProvider}
                entrypoint={API_ENTRYPOINT}
                locale="fr"
                i18nProvider={i18nProvider}
    >
      <ResourceGuesser
        name="establishments"
        list={EstablishmentList}
        show={EstablishmentShow}
        create={EstablishmentCreate}
        edit={EstablishmentEdit}
        icon={Home}
      />
      <ResourceGuesser
        name="academic-years"
        list={AcademicYearList}
        show={AcademicYearShow}
        create={AcademicYearCreate}
        edit={AcademicYearEdit}
        icon={School}
      />
      <ResourceGuesser
        name="subjects"
        list={SubjectList}
        show={SubjectShow}
        create={SubjectCreate}
        edit={SubjectEdit}
        icon={Palette}
      />
      <ResourceGuesser
        name="school-classes"
        list={SchoolClassList}
        show={SchoolClassShow}
        create={SchoolClassCreate}
        edit={SchoolClassEdit}
        icon={LocalLibrary}
      />
      <ResourceGuesser
        name="programs"
        list={ProgramList}
        show={ProgramShow}
        create={ProgramCreate}
        edit={ProgramEdit}
        icon={LibraryBooks}
      />
      <ResourceGuesser
        name="users"
        list={UserList}
        show={UserShow}
        create={UserCreate}
        edit={UserEdit}
        icon={People}
      />
      <Resource
        name="user-details"
        list={UserDetailList}
        show={UserDetailShow}
        create={UserDetailCreate}
        edit={UserDetailEdit}
      />
      <ResourceGuesser
        name="exams"
        list={ExamList}
        show={ExamShow}
        edit={ExamEdit}
        icon={Event}
      />
      <ResourceGuesser
        name="assessment-types"
        list={AssessmentTypeList}
        show={AssessmentTypeShow}
        create={AssessmentTypeCreate}
        edit={AssessmentTypeEdit}
      />
      <ResourceGuesser
        name="onboarding-custom-texts"
        list={OnboardingCustomTextList}
        show={OnboardingCustomTextShow}
        create={OnboardingCustomTextCreate}
        edit={OnboardingCustomTextEdit}
      />
      <ResourceGuesser
        name="lms-configurations"
        list={LmsConfigurationList}
        show={LmsConfigurationShow}
        create={LmsConfigurationCreate}
        edit={LmsConfigurationEdit}
      />
      <ResourceGuesser name="establishment-params" />
    </HydraAdmin>
  );
}

