import React from "react";
import {
  ReferenceField,
  TextField,
  Filter,
  SearchInput,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
} from 'react-admin';
import {
  FieldGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn/>
    <ReferenceArrayInput source="establishment" reference="establishments">
      <SelectArrayInput optionText="name"/>
    </ReferenceArrayInput>
    <BooleanInput source="enabled"/>
  </Filter>
);

const UserDetailList = props => (
  <List {...props} filters={<PostFilter />} bulkActionButtons={<PostBulkActionButtons />} perPage={25}>
    <Datagrid >
      <FieldGuesser source="email" />
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <FieldGuesser source="roles" />
      <FieldGuesser source="hasExtraTime" />
      <FieldGuesser source="locale" />
      <FieldGuesser source="studentNumber" />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteWithConfirmButton confirmTitle='ra.action.delete' confirmContent='ra.message.delete_content' label="" />
    </Datagrid >
  </List >
);

export default UserDetailList;
