import React from "react";
import { EditGuesser } from "@api-platform/admin";
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  BooleanInput,
  useNotify
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

const AssessmentTypeForm = () => {
  const translate = useTranslate();
  const notify = useNotify();
  return (
    <Box p="1em">
      <Box display="flex">

        <Box flex={1} mr="4em">
          <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
          <TextInput source="name" label='resources.assessment-types.fields.name' fullWidth />
          <ReferenceInput source="establishment" reference="establishments" label='resources.assessment-types.fields.establishment' onChange={()=>notify(`resources.changeEstablishment`,'warning')} fullWidth perPage={1000}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <BooleanInput source="doubleApproval" label='resources.assessment-types.fields.doubleApproval' fullWidth />
          <BooleanInput source="isDefault" fullWidth />
        </Box>

      </Box>
    </Box >
  )
}
const AssessmentTypeEdit = props => (
  <EditGuesser {...props}>
    <AssessmentTypeForm />
  </EditGuesser>
);

export default AssessmentTypeEdit;
