import React from "react";
import {
    ReferenceInput,
    SelectInput,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { useTranslate } from "react-admin";

export const SchoolClassForm = () => {
    const translate = useTranslate();
    return (
        <Box p="1em">
            <Box display="flex">

                <Box flex={1} mr="4em">
                    <Typography variant="h4" gutterBottom>{translate("resources.general")}</Typography>
                    <TextInput source="name" label='resources.school-classes.fields.name' fullWidth />
                    <ReferenceInput source="academicYear" reference="academic-years" label='resources.school-classes.fields.academicYear' fullWidth  perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceArrayInput source="subjects" reference="subjects" label='resources.school-classes.fields.subjects' fullWidth perPage={1000}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="students" reference="users" label='resources.school-classes.fields.students' fullWidth perPage={1000}>
                        <SelectArrayInput optionText="lastName" />
                    </ReferenceArrayInput>
                    <ReferenceInput source="establishment" reference="establishments" label='resources.school-classes.fields.establishment' fullWidth perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </Box>

            </Box>
        </Box >
    )
}


