import React from "react";
import {
  TabbedShowLayout,
  Tab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  TextField,
  ShowButton,
  ReferenceField,
  Pagination,
  NumberField,
} from 'react-admin'
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ExamShow = props => (
  <ShowGuesser {...props}>
    <TabbedShowLayout>

      <Tab label="resources.general">
        <FieldGuesser source="name" addLabel={true} />
        <ReferenceField source="subject" reference="subjects" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="academicYear" reference="academic-years" link="show">
          <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="nbClasses" addLabel={true} />
        <ReferenceArrayField source="classes" reference="school-classes" sortable={false} link="show">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FieldGuesser source="validatedAt" addLabel={true} />
        <FieldGuesser source="startDate" addLabel={true} showTime />
        <FieldGuesser source="duration" addLabel={true} />
        <FieldGuesser source="maxPoints" addLabel={true} />
        <FieldGuesser source="instructions" addLabel={true} />
        <FieldGuesser source="status" addLabel={true} />
        <FieldGuesser source="nbProfessors" addLabel={true} />
        <FieldGuesser source="nbGraders" addLabel={true} />
        <FieldGuesser source="hash" addLabel={true} />
        <FieldGuesser source="publicKey" addLabel={true} />
        <FieldGuesser source="proctorCode" addLabel={true} />
        <FieldGuesser source="hasTimer" addLabel={true} />
        <ReferenceField source="createdBy" reference="users" link="show">
          <TextField source="lastName" />
        </ReferenceField>
        <FieldGuesser source="createdAt" addLabel={true} />
        <FieldGuesser source="updatedAt" addLabel={true} />
        <FieldGuesser source="receivedStudentPapers" addLabel={true} />
        <FieldGuesser source="timeZeroSecond" addLabel={true} />
        <FieldGuesser source="type" addLabel={true} />
        <ReferenceField source="template" reference="exams" link="show">
          <TextField source="name"/>
        </ReferenceField>
      </Tab>

      <Tab label="Parametres" path="exam-params">
        <ReferenceField source="examParams" reference="exam-params" addlink={false} label="resources.exam-params.fields.allowSpellChecker">
          <FieldGuesser source="allowSpellChecker" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.allowCopyAndPaste">
          <FieldGuesser source="allowCopyAndPaste" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.anonymousCorrection">
          <FieldGuesser source="anonymousCorrection" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.scientificCalculator">
          <FieldGuesser source="scientificCalculator" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.basicCalculator">
          <FieldGuesser source="basicCalculator" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.proctoring">
          <FieldGuesser source="proctoring" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.openBook">
          <FieldGuesser source="openBook" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.allowTierTime">
          <FieldGuesser source="allowTierTime" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.showNoCorrectAnswer">
          <FieldGuesser source="showNoCorrectAnswer" addLabel={true} />
        </ReferenceField>
      </Tab>
  
      <Tab label="FTP">
        <ReferenceField source="examParams" reference="exam-params" addlink={false} label="resources.exam-params.fields.deleteFileAfterFtpUpload">
          <FieldGuesser source="deleteFileAfterFtpUpload" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.encryptFileOnFtp">
          <FieldGuesser source="encryptFileOnFtp" addLabel={true} />
        </ReferenceField>
        <ReferenceField reference="exam-params" source="examParams" addlink={false} label="resources.exam-params.fields.sendWithFtp">
          <FieldGuesser source="sendWithFtp" addLabel={true} />
        </ReferenceField>
      </Tab>

      <Tab label="Utilisateurs concernés" path="exam-users">
        <ReferenceArrayField reference="school-classes" source="classes" addLabel={true} label="Classes" link="show">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="users" source="professors" pagination={<PostPagination />}>
          <Datagrid>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
        <ReferenceArrayField reference="users" source="proctors" pagination={<PostPagination />}>
          <Datagrid>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
        <ReferenceArrayField reference="student-paper-distributions" source="studentPapersDistributions"
          pagination={<PostPagination />}>
          <Datagrid>
            <ReferenceField source="grader" reference="users" link="show" label="Prénom">
              <TextField source="firstName" />
            </ReferenceField>
            <ReferenceField source="grader" reference="users" link="show" label="Nom">
              <TextField source="lastName" />
            </ReferenceField>
            <NumberField options={{ maximumFractionDigits: 2 }} source="percentage" label="Pourcentage" />
            <NumberField source="receivedStudentPapers" label="Copies attribuées" />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>

    </TabbedShowLayout>
  </ShowGuesser>
);

export default ExamShow;
