import React from "react";
import {
  ReferenceField,
  TextField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  TabbedShowLayout,
  Tab,
  Datagrid,
  ShowButton,
  EditButton,
  Pagination,
  Button,
  Link,
} from 'react-admin';
import {
  ShowGuesser,
  FieldGuesser
} from "@api-platform/admin";

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
// const AddNewUserDetailButton = ({ record }) => {
//   console.log(record);
//   return (
//     <Button
//       component={Link}
//       to={{
//         pathname: "/user-details/create/e5d88954-e5df-4a05-87a0-601db061c4c6",
//         // Here we specify the initial record for the create view
//         state: { record: { user_id: record.id } },
//       }}
//       label="+ Create new user detail"
//     >
//     </Button>
//   )
// };
const UserShow = props => (
  <ShowGuesser {...props}>
    <TabbedShowLayout>
      <Tab label="Général">
        <FieldGuesser source="firstName" addLabel={true}/>
        <FieldGuesser source="lastName" addLabel={true}/>
        <FieldGuesser source="username" addLabel={true}/>
        <FieldGuesser source="email" addLabel={true}/>
        <FieldGuesser source="roles" addLabel={true}/>
        <FieldGuesser source="avatar" addLabel={true}/>
        <FieldGuesser source="hasExtraTime" addLabel={true}/>
        <FieldGuesser source="hasTakenTraining" addLabel={true}/>
        <FieldGuesser source="takenTrainingDate" addLabel={true}/>
        <FieldGuesser source="blocked" addLabel={true}/>
        <ReferenceArrayField source="schoolClasses" reference="school-classes" link="show">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="establishments" source="establishments" addLabel={true} link="show">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField reference="assessment-types" source="professorAssessmentTypes" addLabel={true} link="show">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="createdBy" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="createdAt" addLabel={true}/>
        <FieldGuesser source="updatedAt" addLabel={true}/>
      </Tab>
      <Tab label="User Details" path="user-details">
        {/*<AddNewUserDetailButton/>*/}
        <ReferenceArrayField reference="user-details" source="userDetails">
          <Datagrid>
            <ReferenceField source="establishment" reference="establishments" addLabel={true} label="Establishment">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="roles"/>
            <ShowButton/>
            <EditButton/>
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </ShowGuesser>
);

export default UserShow;
