import React from "react";
import { EditGuesser } from "@api-platform/admin";
import { LmsConfigurationForm } from "./LmsConfigurationForm";

const LmsConfigurationEdit = props => (
  <EditGuesser {...props}>
    <LmsConfigurationForm />
  </EditGuesser>
);

export default LmsConfigurationEdit;
