import React  from "react";
import {
  // eslint-disable-next-line no-unused-vars
  DeleteWithConfirmButton,
  ShowButton,
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField
} from 'react-admin';
import {
  FieldGuesser
} from "@api-platform/admin";
import { PostBulkActionButtons } from "../ActionButtons";

const OnboardingCustomTextList = props => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid >
      <FieldGuesser source="locale" />
      <FieldGuesser source="statementTitle" />
      <FieldGuesser source="statementSubtitle" />
      <ReferenceField source="establishment" reference="establishments" sortBy="establishment.name">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton />
      <EditButton />
      <DeleteWithConfirmButton confirmTitle = 'ra.action.delete' confirmContent = 'ra.message.delete_content' />
    </Datagrid >
  </List >
);

export default OnboardingCustomTextList;
