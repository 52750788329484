import React from "react";
import {ReferenceInput, TextInput, SelectInput} from 'react-admin';
import {
  CreateGuesser,
  InputGuesser,
} from "@api-platform/admin";

const OnboardingCustomTextCreate = props => (
  <CreateGuesser {...props}>
    <TextInput source="locale"/>
    <InputGuesser source="statementTitle"/>
    <InputGuesser source="statementSubtitle"/>
    <ReferenceInput source="establishment" reference="establishments" perPage={1000}>
      <SelectInput optionText="name"/>
    </ReferenceInput>
  </CreateGuesser>
);

export default OnboardingCustomTextCreate;
